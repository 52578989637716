import React, { useEffect, useState } from 'react';
import { Button, message, Pagination, Space, Switch, Table } from 'antd';
import { useMutation, useQuery } from 'react-query';
import {deleteReplies, getReplies, pageSize, putRepliesStatus } from '../../../api'; 
import { queryClient } from '../../../queryClient';
import {
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useReplies } from './store';
import { useSearchParams } from 'react-router-dom';

export default function PureTable({ onEdit }) {
  const [page, setPage] = useState(1);
   const [searchParams, setSearchParams] = useSearchParams();
  const{queryKey}=useReplies();

   useEffect(() => {
      if (searchParams.get("page_num")) {
        setPage(searchParams.get("page_num"));
      } else {
        setPage(1);
      }
    }, [searchParams.get("page_num")]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 80,
    },
    {
      title: "Ticket Status",
      dataIndex: "type",
      key: "type",
      width: 100,
    },

     {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 50,
          render: (status, record) => (
            <Switch
              checked={status === true}
              onChange={(checked) => handleStatusChange(record.id, checked)}
            />
          ),
        },
    {
      title: "Actions",
      key: "actions",
      width: 120,
      render: (_, record) => (
        <Space>
          <Button size="small" onClick={() => onEdit(record)} icon={<EditOutlined />}></Button>
          <Button size="small" danger onClick={() => handleDelete(record.id)}  icon={<DeleteOutlined />}></Button>
        </Space>
      ),
    },
  ];

  const {
    isLoading: loading,
    data,
  } = useQuery(
    ["replies", page,queryKey],
    getReplies,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { mutate: deleteMutate } = useMutation((id) => deleteReplies(id), {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("replies");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

    const { mutate: statusMutation } = useMutation(putRepliesStatus, {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("replies");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    });
    const handleStatusChange = (id, checked) => {
        statusMutation({ id, status: checked }); 
      };
     

  const handleDelete = (id) => {
    deleteMutate(id); 
  };

 


  const dataSource = data?.data?.records || [];
  const total = data?.data?.total || 0;
  

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        size="small"
        pagination={false} 
      />
      <br />
      <Pagination
        current={page}
        onChange={(val) => setPage(val)}
        defaultPageSize={pageSize}
        total={total}
        showSizeChanger={false}
      />
    </>
  );
}
