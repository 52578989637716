import React, { useEffect, useState } from "react";
import { Card, Breadcrumb, Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PureTable } from "./table";
import { ReasonsCreateModal } from "./create";
import { useReasons } from "./store";
import { useSearchParams } from "react-router-dom";
import authFunc from "../../../helper/auth";

const { Search } = Input;
export const ReasonsPage = () => {
  const { setIsCreateModal, queryKey, setQueryKey,setEditRecord } = useReasons();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, [queryKey]);

  useEffect(() => {
    const queryValue = searchParams.get("q");
    if (queryValue) {
      setSearch(queryValue);
      setQueryKey(queryValue);
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams, setQueryKey]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Tickets</Breadcrumb.Item>
        <Breadcrumb.Item>Reasons</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <div className="app-space-between">
          <Search
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSearch={(val) => {
              searchParams.set("q", val);
              searchParams.set("page_num", 1);
              setSearchParams(searchParams);
              setQueryKey(val);
            }}
            style={{ width: 340 }}
            placeholder="Write To Search..."
          />
             {authFunc("TICKET_REASON_CREATE") && (
          <Button
            onClick={() => {
              setEditRecord(null)
              setIsCreateModal(true)}}
            type="primary"
            icon={<PlusOutlined />}
          >
            Create New
          </Button>
             )}
        </div>

        <br />
        <PureTable />
        <ReasonsCreateModal />
      </Card>
    </div>
  );
};
