import create from "zustand";

export const useReasons = create((set) => ({
  isCreateModal: false,
  isCreateSubModal: false,
  queryKey:'',
  editRecord: null,
 
  editSubReason:null,
  setEditSubReason: (editSubReason) => set({ editSubReason }),
  
  setEditRecord: (record) => set({ editRecord: record }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setIsCreateSubModal: (isCreateSubModal) => set({ isCreateSubModal }),
  setQueryKey: (queryKey) => set({ queryKey }),
}));
