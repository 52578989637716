import React, { useEffect } from "react";
import { Input, DatePicker, Select, Checkbox, Row, Col } from "antd";
import { useList } from "./store";
import moment from "moment";

const { RangePicker } = DatePicker;

const Filters = () => {
  const { filters, setFilters } = useList();

  useEffect(() => {
       if (!filters.dateRange) {
      const from = moment().startOf('day').add(8, 'hours').format("YYYY-MM-DD HH:mm:ss");
      const to = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");
      setFilters((prev) => ({
        id: prev.id || "",
        customer_phone: prev.customer_phone || "",
        dateRange: [from, to],  
        status: prev.status || "",
        filterDuplicates: prev.filterDuplicates || false,
      }));
    } else {
      setFilters((prev) => ({
        id: prev.id || "",
        customer_phone: prev.customer_phone || "",
        dateRange: prev.dateRange || null,
        status: prev.status || "",
        filterDuplicates: prev.filterDuplicates || false,
      }));
    }
  }, [setFilters, filters.dateRange]);

 

  const handleChange = (key, value) => {
    if (key === "customer_phone") {
      if (value.length === 11) {
        setFilters((prev) => ({ ...prev, customer_phone: value }));
      }
    } else if (key === "dateRange") {
      if (value && value.length === 2) {
        const from = value[0]
          ? moment(value[0]).format("YYYY-MM-DD HH:mm:ss")
          : null;
        const to = value[1]
          ? moment(value[1]).format("YYYY-MM-DD HH:mm:ss")
          : null;

        setFilters((prev) => ({ ...prev, dateRange: [from, to] }));
      } else {
        setFilters((prev) => ({ ...prev, dateRange: null }));
      }
    } else {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  return (
    <Row
      gutter={16}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <Col flex="1">
        <Input
          placeholder="Search Ticket ID"
          value={filters.id}
          onChange={(e) => handleChange("id", e.target.value)}
        />
      </Col>
      <Col flex="1">
        <Input
          placeholder="Search Customer Number"
          defaultValue={filters.customer_phone}
          onChange={(e) => handleChange("customer_phone", e.target.value)}
           maxLength="11"
                minLength="11"
        />
      </Col>
      <Col flex="3">
        <RangePicker
          value={
            filters.dateRange
              ? [moment(filters.dateRange[0]), moment(filters.dateRange[1])]
              : null
          }
          onChange={(dates) => handleChange("dateRange", dates)}
          style={{ width: "100%" }}
          showTime
        />
      </Col>
      <Col flex="1">
        <Select
          placeholder="Filter Status"
          value={filters.status}
          onChange={(value) => handleChange("status", value)}
          style={{ width: "100%" }}
          allowClear
        >
          <Select.Option value="PENDING">Pending</Select.Option>
          <Select.Option value="IN_PROGRESS">In Progress</Select.Option>
          <Select.Option value="WAITING_RESPONSE">
            Waiting Response
          </Select.Option>
          <Select.Option value="RESOLVED">Resolved</Select.Option>
          <Select.Option value="NOT_RESOLVED">Not Resolved</Select.Option>
        </Select>
      </Col>
      <Col flex="1" style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          checked={filters.is_duplicate}
          onChange={(e) => handleChange("is_duplicate", e.target.checked)}
        >
          Filter Duplicates
        </Checkbox>
      </Col>
    </Row>
  );
};

export default Filters;
