import React, { useState, useEffect } from "react";
import { Modal, Input, Button, Spin, message } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { useAppBanner } from "./store";
import { getNearbyVendor, putNearbyVendor } from "../../api";
import { queryClient } from "../../queryClient";

const NearbyVendorsModal = () => {
  const { setVendorModal, vendorModal } = useAppBanner();
  const [sectionCount, setSectionCount] = useState("");
  const [id, setId] = useState("");
  const [priority, setPriority] = useState("");

  const { isLoading, error, data } = useQuery(
    ["NearByVendor"],
    getNearbyVendor,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: vendorModal,
    }
  );

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    putNearbyVendor,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setVendorModal(false);
          queryClient.invalidateQueries("NearByVendor");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  useEffect(() => {
    if (data) {
      setSectionCount(data?.data?.section_count || "");
      setId(data?.data?.id || "");
      setPriority(data?.data?.priority || "");
    }
  }, [data]);

  const handleSave = () => {
    editMutate({
      section_count: Number(sectionCount),
      id: Number(id),
      priority: Number(priority),
    });
  };

  return (
    <Modal
      title={<strong style={{ fontSize: "18px" }}>Nearby Vendors</strong>}
      centered
      visible={vendorModal}
      onCancel={() => setVendorModal(false)}
      footer={null}
    >
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        {isLoading ? (
          <Spin />
        ) : (
          <>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                No. of Vendors
              </label>
              <Input
                placeholder="Enter number"
                value={sectionCount}
                onChange={(e) => setSectionCount(e.target.value)}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                Priority
              </label>
              <Input
                placeholder="Enter priority"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
              />
            </div>
          </>
        )}
      </div>

      <Button
        style={{ marginTop: "20px" }}
        type="primary"
        icon={<SaveOutlined />}
        onClick={handleSave}
        loading={editLoading}
      >
        Save
      </Button>
    </Modal>
  );
};

export default NearbyVendorsModal;
