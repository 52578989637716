import {
  Table,
  Switch,
  Space,
  Button,
  Tooltip,
  Spin,
  Pagination,
  message,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  deleteReasons,
  getReasons,
  pageSize,
  putReasonsStatus,
} from "../../../api";
import { useMutation, useQuery } from "react-query";
import { useReasons } from "./store";
import { queryClient } from "../../../queryClient";
import { ReasonsCreateModal } from "./create";

export const PureTable = () => {
  const { setIsCreateModal, queryKey, setEditRecord, setReasonId } =
    useReasons();
  const navigate = useNavigate();
  const [page, setpage] = useState(1);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setpage(searchParams.get("page_num"));
    } else {
      setpage(1);
    }
  }, [searchParams.get("page_num")]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Switch
          checked={status === true}
          onChange={(checked) => handleStatusChange(record.id, checked)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Tooltip title="Sub Reasons">
            <Button
              icon={<FolderOpenOutlined />}
              onClick={() => {
                
                setEditRecord(record);
                navigate(`/tickets/reasons/${record.id}/sub-reasons`)
              }}
              size="small"
            >
              Sub Reasons
            </Button>
          </Tooltip>

          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditRecord(record);
                setIsCreateModal(true);
              }}
              size="small"
            />
          </Tooltip>

          <Tooltip title="Delete">
            <Button
              icon={<DeleteOutlined />}
              size="small"
              danger
              onClick={() => handleDelete(record.id)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const { isLoading, data, error } = useQuery(
    ["reasons", page, queryKey],
    getReasons,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { mutate: statusMutation } = useMutation(putReasonsStatus, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("reasons");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: deleteMutate } = useMutation((id) => deleteReasons(id), {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("reasons");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const handleDelete = (id) => {
    deleteMutate(id);
  };

  const handleStatusChange = (id, checked) => {
    statusMutation({ id, status: checked });
  };

  if (isLoading) return <Spin size="large" />;
  if (error) return <p style={{ color: "red" }}>Error: {error.message}</p>;

  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.data?.records || []}
        rowKey="id"
        pagination={false}
        size="small"
      />
      <br />
      <Pagination
        current={page}
        onChange={(val) => setpage(val)}
        defaultPageSize={pageSize}
        total={data?.data?.total}
        showSizeChanger={false}
      />

      <ReasonsCreateModal />
    </>
  );
};
