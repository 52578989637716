import create from "zustand";

export const useList = create((set) => ({
  filters: {
    id: "",
    customer_phone: "",
    dateRange: null,
    status: "",
    is_duplicate: false,
  },
  setFilters: (updateFn) =>
    set((state) => ({ filters: updateFn(state.filters) })),
}));
