import React, { useEffect, useState } from "react";
import { Button, Pagination, Table, Tag } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useQuery } from "react-query";
import { getTickets, pageSize } from "../../../api";
import { useList } from "./store";
import dayjs from "dayjs";

const TicketsTable = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { filters, setFilters } = useList();
  const [page, setPage] = useState(Number(searchParams.get("page_num")) || 1);

  useEffect(() => {
    const pageParam = Number(searchParams.get("page_num")) || 1;
    setPage(pageParam);
  }, [searchParams]);

  useEffect(() => {
    setFilters((prev) => ({
      id: prev.id || "",
      customer_phone: prev.customer_phone || "",
      dateRange: prev.dateRange || null,
      status: prev.status || "",
      is_duplicate: prev.is_duplicate || false,
    }));
  }, [setFilters]);

  const columns = [
    { title: "Ticket ID", dataIndex: "id", key: "id" },
    {
      title: "Customer Phone",
      dataIndex: "customer_phone",
      key: "customer_phone",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => dayjs(date).format("YYYY-MM-DD HH:mm A"),
    },
    { title: "Order ID", dataIndex: "order_id", key: "order_id" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const statusColors = {
          PENDING: "orange",
          RESOLVED: "green",
          WAITING_RESPONSE: "blue",
          IN_PROGRESS: "cyan",
          NOT_RESOLVED: "red",
        };

        return (
          <Tag color={statusColors[status] || "gray"}>
            {status.replace("_", " ")}
          </Tag>
        );
      },
    },
    {
      title: "Total Tickets",
      dataIndex: "tickets_count",
      key: "tickets_count",
    },
    {
      title: "Duplicate",
      dataIndex: "duplicate",
      key: "duplicate",
      render: (duplicate) =>
        duplicate ? (
          <CheckCircleOutlined style={{ color: "green", fontSize: "16px" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red", fontSize: "16px" }} />
        ),
    },
    {
      title: "Compensation",
      dataIndex: "compensation_voucher",
      key: "compensation_voucher",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button
          size="small"
          onClick={() => navigate(`/tickets/tickets_list/${record.id}/details`)}
          icon={<EditOutlined />}
        />
      ),
    },
  ];

  const { data, isLoading } = useQuery(["tickets", filters, page], getTickets, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.data?.records || []}
        rowKey="ticketId"
        loading={isLoading}
        pagination={false}
      />
      <br />
      <Pagination
        current={page}
        onChange={(val) => {
          setPage(val);
          setSearchParams({ page_num: val });
        }}
        defaultPageSize={pageSize}
        total={data?.data?.total || 0}
        showSizeChanger={false}
      />
    </>
  );
};

export default TicketsTable;
