import React, { useEffect } from "react";
import { Modal, Form, Input, Typography, Button, InputNumber, Space, message, Select } from "antd";
import { useReplies } from "./store";
import { postReplies, putReplies } from "../../../api";
import { queryClient } from "../../../queryClient";
import { useMutation } from "react-query";

export default function RepliesModal ({ edit }) {
    const [form] = Form.useForm();
    const { isCreateModal, setIsCreateModal } = useReplies();

   
    const { mutate: addMutate, isLoading: loadingAdd } = useMutation(postReplies, {
        onSuccess: (data) => {
            if (data.success) {
                message.success(data.msg);
                setIsCreateModal(false);
                queryClient.invalidateQueries("replies");
            } else {
                message.error(data.msg);
            }
        },
        onError: (e) => {
            message.error(e.message);
        },
    });

    const { mutate: editMutation, isLoading: loadingEdit } = useMutation(putReplies, {
        onSuccess: (data) => {
            if (data.success) {
                message.success(data.msg);
                setIsCreateModal(false);
                queryClient.invalidateQueries("replies");
            } else {
                message.error(data.msg);
            }
        },
        onError: (e) => {
            message.error(e.message);
        },
    });

   
    useEffect(() => {
        if (edit) {
            form.setFieldsValue(edit);
        } else {
            form.resetFields(); 
        }
    }, [edit, form]);

  
    const handleSave = () => {
        form
            .validateFields()
            .then((values) => {
                if (edit) {
                   
                    editMutation({ ...edit, ...values });
                } else {
                  
                    addMutate(values);
                }
                form.resetFields(); 
            })
            .catch((info) => console.log("Validation Failed:", info));
    };

    return (
        <Modal
        title={<Space style={{ width: "100%" }} direction="vertical">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
                {edit ? "Edit" : "Create"} Feedback Template
            </Typography.Title>
        </Space>}
        className="app-modal"
        centered
        visible={isCreateModal}
        onCancel={() => setIsCreateModal(false)}
        footer={false}
    >
        <Form form={form} layout="vertical">
            <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: "Please enter a title!" }]}
                style={{ marginBottom: "16px" }}  
            >
                <Input placeholder="Enter title" />
            </Form.Item>
            <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: "Please enter a description!" }]}
                style={{ marginBottom: "16px" }}  
            >
                <Input.TextArea rows={3} placeholder="Enter description" />
            </Form.Item>

              <Form.Item
                      name="type"
                      label="Ticket Status"
                      rules={[{ required: true, message: "Please select Ticket Status!" }]}
                      style={{ marginBottom: "16px" }}
                    >
                      <Select placeholder="Select Ticket Status">
                        <Select.Option value="PENDING">Pending</Select.Option>
                        <Select.Option value="IN_PROGRESS">In Progress</Select.Option>
                        <Select.Option value="WAITING_RESPONSE">Waiting Response</Select.Option>
                        <Select.Option value="RESOLVED">Resolved</Select.Option>
                        <Select.Option value="NOT_RESOLVED">Not Resolved</Select.Option>
                      </Select>
                    </Form.Item>
    
           
        </Form>
    
        <Button
            loading={loadingAdd || loadingEdit}
            onClick={handleSave}
            type="primary"
            style={{ marginTop: "16px" }}  
        >
            {edit ? "Update" : "Save"}
        </Button>
    </Modal>
    
    );
};


