import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Typography,
  Button,
  Space,
  message,
  Select,
} from "antd";
import { useNotification } from "./store";
import { postNotifications, putNotifications } from "../../../api";
import { queryClient } from "../../../queryClient";
import { useMutation } from "react-query";

export default function NotificationModal({ edit }) {
  const [form] = Form.useForm();
  const { isCreateModal, setIsCreateModal } = useNotification();

  const { mutate: addMutate, isLoading: loadingAdd } = useMutation(
    postNotifications,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("notifications");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const { mutate: editMutation, isLoading: loadingEdit } = useMutation(
    putNotifications,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("notifications");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  useEffect(() => {
    if (isCreateModal) {
      if (edit) {
        form.setFieldsValue({
          title: edit.data?.title || "",
          message: edit.data?.message || "",
          deeplink: edit.data?.deeplink || "",
          campaign_url: edit.config?.campaign_url || "",
          api_key: edit.config?.api_key || "",
          type: edit.type || "IN_PROGRESS",
        });
      } else {
        form.resetFields();
      }
    }
  }, [edit, isCreateModal, form]);

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        const requestData = {
          type: values.type,
          data: {
            title: values.title,
            message: values.message,
            deeplink: values.deeplink,
          },
          config: {
            campaign_url: values.campaign_url,
            api_key: values.api_key,
            send_by: ["Notification"],
          },
        };

        if (edit) {
          editMutation({ ...edit, ...requestData });
        } else {
          addMutate(requestData);
        }

        form.resetFields();
      })
      .catch((info) => console.log("Validation Failed:", info));
  };

  return (
    <Modal
      title={
        <Space style={{ width: "100%" }} direction="vertical">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {edit ? "Edit Notification" : "Create Notification"}
          </Typography.Title>
        </Space>
      }
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => setIsCreateModal(false)}
      footer={null}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please enter a title!" }]}
          style={{marginBottom:'16px'}}
        >
          <Input placeholder="Enter title" />
        </Form.Item>

        <Form.Item
          name="message"
          label="Message"
          rules={[{ required: true, message: "Please enter a message!" }]}
          style={{marginBottom:'16px'}}
        >
          <Input.TextArea rows={3} placeholder="Enter message" />
        </Form.Item>

        <Form.Item
          name="deeplink"
          label="Deep Link"
          rules={[{ required: true, message: "Please enter a deep link!" }]}
          style={{marginBottom:'16px'}}
        >
          <Input placeholder="app://example/path" />
        </Form.Item>

        <Form.Item
          name="campaign_url"
          label="Campaign URL"
          rules={[{ required: true, message: "Please enter a campaign URL!" }]}
          style={{marginBottom:'16px'}}
        >
          <Input placeholder="https://example.com/campaign" />
        </Form.Item>

        <Form.Item
          name="api_key"
          label="API Key"
          rules={[{ required: true, message: "Please enter an API key!" }]}
          style={{marginBottom:'16px'}}
        >
          <Input placeholder="Enter API key" />
        </Form.Item>

        <Form.Item
          name="type"
          label="Ticket Status"
          rules={[
            { required: true, message: "Please select a ticket status!" },
          ]}
          style={{marginBottom:'16px'}}
        >
          <Select placeholder="Select Ticket Status">
            <Select.Option value="PENDING">Pending</Select.Option>
            <Select.Option value="IN_PROGRESS">In Progress</Select.Option>
            <Select.Option value="WAITING_RESPONSE">
              Waiting Response
            </Select.Option>
            <Select.Option value="RESOLVED">Resolved</Select.Option>
            <Select.Option value="NOT_RESOLVED">Not Resolved</Select.Option>
          </Select>
        </Form.Item>
      </Form>

      <Button
        loading={loadingAdd || loadingEdit}
        onClick={handleSave}
        type="primary"
        style={{ marginTop: "16px" }}
      >
        {edit ? "Update" : "Save"}
      </Button>
    </Modal>
  );
}
