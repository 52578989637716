import {
  Button,
  message,
  Pagination,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import {deleteSubReasons, getSubReasons, pageSize, putSubReasonsStatus } from "../../../../api";
import { queryClient } from "../../../../queryClient";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useReasons } from "../store";
import { useParams } from "react-router-dom";
export function PureTable() {
  const { setIsCreateSubModal, setEditSubReason, queryKey } = useReasons();
  const [page, setPage] = useState(1);
  const { id } = useParams(); 


  const { isLoading, data, error } = useQuery(
    id ? ["subreasons", page, queryKey, id] : null,
    getSubReasons,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!id, 
    }
  );

  
  const { mutate: statusMutation } = useMutation(putSubReasonsStatus, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries(["subreasons"]);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: deleteMutate } = useMutation((id) => deleteSubReasons(id), {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries(["subreasons"]);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const handleDelete = (id) => {
    deleteMutate(id);
  };

  const handleStatusChange = (id, checked) => {
    statusMutation({ id, status: checked });
  };

  if (!id) {
    return <div>No sub-reasons available</div>;
  }


  const columns = [
    { title: "#", dataIndex: "id", key: "id" },
    { title: "Title", dataIndex: "title", key: "title" },
    {
      title: "Description",
      dataIndex: "require_description",
      key: "require_description",
      render: (type) => (
        <Tag color={type ? "green" : "red"}>
          {type ? "True" : "False"}
        </Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Switch checked={status === true} onChange={(checked) => handleStatusChange(record.id, checked)} />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditSubReason(record);
                setIsCreateSubModal(true);
               
              }}
              size="small"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button icon={<DeleteOutlined />} size="small" danger onClick={() => handleDelete(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table 
      columns={columns} 
      dataSource={data?.data?.records} 
      rowKey="id"
       pagination={false} 
       size="small" />
      <br />
      <Pagination
        current={page}
        onChange={(val) => setPage(val)}
        defaultPageSize={pageSize}
        total={data?.data?.total}
        showSizeChanger={false}
      />
    </div>
  );
}