import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Typography,
  Button,
  Checkbox,
  Space,
  message,
} from "antd";
import { useMutation } from "react-query";
import { postSubReasons, putSubReasons } from "../../../../api";
import { queryClient } from "../../../../queryClient";
import { useReasons } from "../store";
import { useParams } from "react-router-dom";

export const SubReasonsCreateModal = () => {
  const [form] = Form.useForm();
  const { isCreateSubModal, setIsCreateSubModal, setEditSubReason, editSubReason } = useReasons();
  const { id } = useParams();

  useEffect(() => {
    if (editSubReason) {
      form.setFieldsValue(editSubReason);
    } else {
      form.resetFields();
    }
  }, [editSubReason, form]);

  const { mutate: addMutate, isLoading: loadingAdd } = useMutation(postSubReasons, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateSubModal(false);
        queryClient.invalidateQueries(["subreasons"]);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: loadingEdit } = useMutation(putSubReasons, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateSubModal(false);
        queryClient.invalidateQueries(["subreasons"]);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        const requestData = {
          ...values,
          parent_id: parseInt(id),
          require_description: values.require_description,
        };
        if (editSubReason) {
          editMutate({ ...requestData, id: editSubReason.id });
        } else {
          addMutate(requestData);
        }
        form.resetFields();
        setEditSubReason(null);
      })
      .catch((info) => console.log("Validation Failed:", info));
  };

  return (
    <Modal
      title={
        <Space style={{ width: "100%" }} direction="vertical">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {editSubReason ? "Edit Sub Reason" : "Create Sub Reason"}
          </Typography.Title>
        </Space>
      }
      className="app-modal"
      centered
      visible={isCreateSubModal}
      onCancel={() => {
        setIsCreateSubModal(false);
        setEditSubReason(null);
      }}
      footer={false}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please enter a title!" }]}
          style={{ marginBottom: "16px" }}
        >
          <Input placeholder="Enter title" />
        </Form.Item>

        <Form.Item
          name="require_description"
          valuePropName="checked"
          style={{ marginBottom: "16px" }}
        >
          <Checkbox>
            Require Description
          </Checkbox>
        </Form.Item>
      </Form>

      <Button
        loading={loadingAdd || loadingEdit}
        onClick={handleSave}
        type="primary"
        style={{ marginTop: "16px" }}
      >
        {editSubReason ? "Update" : "Save"}
      </Button>
    </Modal>
  );
};
