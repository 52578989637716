import React, { useEffect } from "react";
import { Modal, Form, Input, Typography, Button, InputNumber, Space, message } from "antd";
import { useTickets } from "./store";
import { postFAQs, putFAQs } from "../../../api";
import { queryClient } from "../../../queryClient";
import { useMutation } from "react-query";

const TicketModal = ({ editingTicket }) => {
    const [form] = Form.useForm();
    const { isCreateModal, setIsCreateModal } = useTickets();

   
    const { mutate: addMutate, isLoading: loadingAdd } = useMutation(postFAQs, {
        onSuccess: (data) => {
            if (data.success) {
                message.success(data.msg);
                setIsCreateModal(false);
                queryClient.invalidateQueries("faqs");
            } else {
                message.error(data.msg);
            }
        },
        onError: (e) => {
            message.error(e.message);
        },
    });

    const { mutate: editMutation, isLoading: loadingEdit } = useMutation(putFAQs, {
        onSuccess: (data) => {
            if (data.success) {
                message.success(data.msg);
                setIsCreateModal(false);
                queryClient.invalidateQueries("faqs");
            } else {
                message.error(data.msg);
            }
        },
        onError: (e) => {
            message.error(e.message);
        },
    });

   
    useEffect(() => {
        if (editingTicket) {
            form.setFieldsValue(editingTicket);
        } else {
            form.resetFields(); 
        }
    }, [editingTicket, form]);

  
    const handleSave = () => {
        form
            .validateFields()
            .then((values) => {
                if (editingTicket) {
                   
                    editMutation({ ...editingTicket, ...values });
                } else {
                  
                    addMutate(values);
                }
                form.resetFields(); 
            })
            .catch((info) => console.log("Validation Failed:", info));
    };

    return (
        <Modal
        title={<Space style={{ width: "100%" }} direction="vertical">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
                {editingTicket ? "Edit" : "Create"} FAQs
            </Typography.Title>
        </Space>}
        className="app-modal"
        centered
        visible={isCreateModal}
        onCancel={() => setIsCreateModal(false)}
        footer={false}
    >
        <Form form={form} layout="vertical">
            <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: "Please enter a title!" }]}
                style={{ marginBottom: "16px" }}  
            >
                <Input placeholder="Enter title" />
            </Form.Item>
    
            <Form.Item
                name="description"
                label="Description"
                style={{ marginBottom: "16px" }}  
            >
                <Input.TextArea rows={3} placeholder="Enter description" />
            </Form.Item>
    
            <Form.Item
                name="priority"
                label="Priority"
                rules={[{ required: true, message: "Select priority" }]}
                style={{ marginBottom: "16px" }}  
            >
                <InputNumber style={{ width: "100%" }} />
            </Form.Item>
        </Form>
    
        <Button
            loading={loadingAdd || loadingEdit}
            onClick={handleSave}
            type="primary"
            style={{ marginTop: "16px" }}  
        >
            {editingTicket ? "Update" : "Save"}
        </Button>
    </Modal>
    
    );
};

export default TicketModal;
