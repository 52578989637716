import React, { useEffect, useState } from "react";
import { Button, message, Pagination, Space, Switch, Table } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  deleteNotifications,
  getNotifications,
  pageSize,
  putNotificationsStatus,
} from "../../../api";
import { queryClient } from "../../../queryClient";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNotification } from "./store";
import { useSearchParams } from "react-router-dom";

export default function PureTable({ onEdit }) {
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const { queryKey } = useNotification();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(Number(searchParams.get("page_num")));
    } else {
      setPage(1);
    }
  }, [searchParams]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Ticket Status",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Title",
      dataIndex: ["data", "title"],
      key: "title",
    },
    {
      title: "Message",
      dataIndex: ["data", "message"],
      key: "message",
    },
    {
      title: "Deeplink",
      dataIndex: ["data", "deeplink"],
      key: "deeplink",

      render: (deeplink) => (
        <a href={deeplink} target="_blank" rel="noopener noreferrer">
          {deeplink}
        </a>
      ),
    },
    {
      title: "Campaign URL",
      dataIndex: ["config", "campaign_url"],
      key: "campaign_url",

      render: (url) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      ),
    },
    {
      title: "API Key",
      dataIndex: ["config", "api_key"],
      key: "api_key",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (status, record) => (
        <Switch
          checked={status}
          onChange={(checked) => handleStatusChange(record.id, checked)}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",

      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => onEdit(record)}
            icon={<EditOutlined />}
          ></Button>
          <Button
            size="small"
            danger
            onClick={() => handleDelete(record.id)}
            icon={<DeleteOutlined />}
          ></Button>
        </Space>
      ),
    },
  ];

  const { isLoading: loading, data } = useQuery(
    ["notifications", page, queryKey],
    getNotifications,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { mutate: deleteMutate } = useMutation(
    (id) => deleteNotifications(id),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("notifications");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const { mutate: statusMutation } = useMutation(putNotificationsStatus, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("notifications");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const handleStatusChange = (id, checked) => {
    statusMutation({ id, status: checked });
  };

  const handleDelete = (id) => {
    deleteMutate(id);
  };

  const dataSource = data?.data?.records || [];
  const total = data?.data?.total || 0;

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        size="small"
        pagination={false}
        rowKey="id"
      />
      <br />
      <Pagination
        current={page}
        onChange={(val) => setPage(val)}
        defaultPageSize={pageSize}
        total={total}
        showSizeChanger={false}
      />
    </>
  );
}
