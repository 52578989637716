import React, { useState, useEffect } from "react";
import { Card, Breadcrumb, Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PureTable from "./table";
import { useReplies } from "./store";
import authFunc from "../../../helper/auth";
import { useSearchParams } from "react-router-dom";
import  RepliesModal from "./create";

const { Search } = Input;
export function RepliesPage() {

     const [edit, setEdit] = useState(null);
      const { isCreateModal, setIsCreateModal, setQueryKey, queryKey } = useReplies();
      const [search, setSearch] = useState("");
      const [searchParams, setSearchParams] = useSearchParams();

       useEffect(() => {
          if (queryKey) {
            setSearch(queryKey);
          }
        }, [queryKey]);
      
        useEffect(() => {
          const queryValue = searchParams.get("q");
          if (queryValue) {
            setSearch(queryValue);
            setQueryKey(queryValue);
          } else {
            setSearch("");
            setQueryKey("");
          }
        }, [searchParams, setQueryKey]);
      
        const handleEdit = (ticket) => {
          setEdit(ticket);
          setIsCreateModal(true);
        };
      
        const handleSave = () => {
          setIsCreateModal(false);
          setEdit(null);
        };
    

  return (
     <div className="app-page">
          <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Tickets</Breadcrumb.Item>
            <Breadcrumb.Item>Feedback Template</Breadcrumb.Item>
          </Breadcrumb>
    
          <Card>
            <div className="app-space-between" style={{ marginBottom: 16 }}>
              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSearch={(val) => {
                  searchParams.set("q", val);
                  searchParams.set("page_num", 1);
                  setSearchParams(searchParams);
                  setQueryKey(val);
                }}
                style={{ width: 340 }}
                placeholder="Write To Search..."
              />
              {authFunc("TICKET_FEEDBACK_TEMPLATE_CREATE") && (
                <Button
                  onClick={() => {
                    setEdit(null);
                    setIsCreateModal(true);
                  }}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  Create New
                </Button>
              )}
            </div>
    
            <PureTable onEdit={handleEdit} />
            <RepliesModal edit={edit} onSave={handleSave} /> 
          </Card>
        </div>
  )
}
