import React from "react";
import TicketsTable from "./table";
import Filters from "./filters";
import { Breadcrumb, Button, Card } from "antd";

export default function TicketsPage() {
  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Tickets</Breadcrumb.Item>
        <Breadcrumb.Item>Tickets List</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <div className="app-space-between" style={{ marginBottom: 16 }}>
          <Filters />
        </div>

        <TicketsTable />
      </Card>
    </div>
  );
}
