import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Typography,
  Button,
  Select,
  Space,
  message,
} from "antd";
import { useReasons } from "./store";
import { postReasons, putReasons } from "../../../api";
import { queryClient } from "../../../queryClient";
import { useMutation } from "react-query";

export const ReasonsCreateModal = () => {
  const [form] = Form.useForm();
  const { isCreateModal, setIsCreateModal,editRecord, setEditRecord  } = useReasons();

  const { mutate: addMutate, isLoading: loadingAdd } = useMutation(
    postReasons,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("reasons");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const { mutate: editMutation, isLoading: loadingEdit } = useMutation(
    putReasons,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("reasons");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  useEffect(() => {
    if (editRecord) {
      form.setFieldsValue(editRecord);
    } else {
      form.resetFields();
    }
  }, [editRecord, form]);

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        if (editRecord) {
          editMutation({ id: editRecord.id, ...values });
        } else {
          addMutate(values);
        }
        setEditRecord(null); 
        form.resetFields();
      })
      .catch((info) => console.log("Validation Failed:", info));
  };

  return (
    <Modal
      title={
        <Space style={{ width: "100%" }} direction="vertical">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {editRecord ? "Edit" : "Create"} Reasons
          </Typography.Title>
        </Space>
      }
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        setIsCreateModal(false);
        setEditRecord(null);
        form.resetFields();
      }}
      footer={false}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please enter a title!" }]}
          style={{ marginBottom: "16px" }}
        >
          <Input placeholder="Enter title" />
        </Form.Item>

        <Form.Item
          name="type"
          label="Reason"
          rules={[{ required: true, message: "Please select a main reason!" }]}
          style={{ marginBottom: "16px" }}
        >
          <Select placeholder="Select main reason">
            <Select.Option value="FOOD">FOOD</Select.Option>
            <Select.Option value="DELIVERY">DELIVERY</Select.Option>
          </Select>
        </Form.Item>
      </Form>

      <Button
        loading={loadingAdd || loadingEdit}
        onClick={handleSave}
        type="primary"
        style={{ marginTop: "16px" }}
      >
        {editRecord ? "Update" : "Save"}
      </Button>
    </Modal>
  );
};
