import { Card, Button, Breadcrumb, Input } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { PureTable } from "./table";
import { SubReasonsCreateModal } from "./create";
import { useReasons } from "../store";
import { PlusOutlined } from "@ant-design/icons";
import authFunc from "../../../../helper/auth";
import { useEffect, useState } from "react";

const { Search } = Input;
export const SubReasonsPage = () => {
  const { isCreateSubModal,setIsCreateSubModal,setEditSubReason,setQueryKey,queryKey } = useReasons();
   const [search, setSearch] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
  
    useEffect(() => {
      if (queryKey) {
        setSearch(queryKey);
      }
    }, [queryKey]);
  
    useEffect(() => {
      const queryValue = searchParams.get("q");
      if (queryValue) {
        setSearch(queryValue);
        setQueryKey(queryValue);
      } else {
        setSearch("");
        setQueryKey("");
      }
    }, [searchParams, setQueryKey]);
  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Tickets</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/reasons">Reasons</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>SubReasons</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
      <div className="app-space-between">
          <Search
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSearch={(val) => {
              searchParams.set("q", val);
              searchParams.set("page_num", 1);
              setSearchParams(searchParams);
              setQueryKey(val);
            }}
            style={{ width: 340 }}
            placeholder="Write To Search..."
          />

            {authFunc("TICKET_REASON_CREATE") && (
          <Button
            onClick={() => {
              setEditSubReason(null)
              setIsCreateSubModal(true)
             
            }}
              
            type="primary"
            icon={<PlusOutlined />}
          >
            Create New
          </Button>
            )}
        </div>
    

        <br />
        <PureTable />
        <SubReasonsCreateModal />
      </Card>
    </div>
  );
};
